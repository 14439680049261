@import "styles/mixings.scss";

//fonts
@import url("https://db.onlinewebfonts.com/c/69b1de69cd6d7ab6309c85af7ee4ec2b?family=Neue+Helvetica+W01+39+Comp+Thin");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Holtwood+One+SC&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Slabo+13px&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Holtwood+One+SC&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Slabo+13px&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Holtwood+One+SC&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @include media-breakpoint-min(xs) { width: 100% ;}
// @include media-breakpoint-max(sm) { width: 100%; }
// @include media-breakpoint-between(md, lg) { width:100%; }
// @include media-breakpoint-max(xlg) { width: 100%; }

:root {
  --primary-color-dark: #fff;
  --primary-color-light: #fff;
  --secondary-color-dark: #fff;
  --secondary-color-light: #fff;
  --background-color-grey: #fff;

  --color-grey-dark: #999999;
  --color-grey-medium: #cccccc;
  --color-grey-light: #e0e0e0;
  --color-black: #000;
  --color-white: #ffffff;

  --panels-background-dark: #f7f8ff;
  --panels-background-light: #ffffff;

  --header-background-color: #fff;
  --footer-background-color: #fff;
  --color-footer: #ffffff;

  --bedges-blue: #e8f6fe;
  --bedges-green: #ade6c8;

  --border-color: #f4f4f4;
  --border-radius: 10px;
  --border-radius-small: 4px;
  --border-radius-medium: 10px;
  --border-radius-large: 50%;

  --active-background: #b5d2f7;
  --active-color: #3c3c3c;

  --section-padding: 1rem;
  --section-margin: 1rem; //section is parent of block
  --section-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

  --block-margin: 0.5rem; //block is child of scetion
  --block-padding: 0.5rem;
  --section-shadow: 0 0.5rem 0.3rem rgb(0 0 0 / 15%);

  --button-margin: 0.5rem;
  --button-padding: 0.5rem 2rem;
  --link-margin: 0.5rem;
  --form-field-margin: 0.5rem;

  --font-family-content: "Open Sans", sans-serif;
  --font-family-headers: "Poppins", sans-serif;

  --chart-green: #5f8672; //chart line
  --chart-green-light: #ade6c8; //
  --chart-red: #db614f; //chart line
  --chart-red-light: #f8a79b; //

  --text-color: #3c3c3c;
  --text-bold-color: #000000;
  --text-color-light: #ffffff;
  --text-color-pale: #808080;

  --footer-link-color: #000;

  --retail-catalog-desc-bg-color: rgba(254, 254, 254, 0.6);
  --retail-catalog-desc-text-color: #000;

  --text-size-small: 0.75rem;
  --text-size-medium: 0.9rem;
  --text-size-large: 1rem;
  --text-size-xl: 1.5rem;
  --text-weight-content: 400;
  --text-weight-titles: 700;

  --text-line-height: 1.5rem;

  --primary-active-link-color: var(--primary-color-dark);
  --login-image: "/images/login.jpg";
}

/**
Global CSS
*/
.text-industry {
  color: var(--primary-color-industry) !important;
}

body {
  opacity: 0;
  animation: fadeInBody 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  height: 100%;
  font-family: var(--font-family-content) !important;
  #root {
    min-height: 100%;
    .card {
      background-color: transparent;
    }
    .single-card {
      img {
        max-width: 100%;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--font-family-headers) !important;
  }
}
@keyframes fadeInBody {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
#container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}
.container-component {
  margin: 6rem 0;
}
.btn.btn-primary {
  background-color: var(--primary-color-dark) !important;
  color: var(--primary-color-light) !important;
  border: 1px solid var(--primary-color-light);
  &:hover {
    border: 1px solid var(--primary-color-light);
  }
}
a {
  color: var(--primary-color-dark);
}

//helpers

.h-20 {
  height: 20rem;
}
.h-30 {
  height: 30rem;
}
.h-45 {
  height: 28rem;
}
.h-15 {
  height: 15rem;
}
.w-15 {
  width: 15rem;
}
.w-10 {
  width: 10rem;
}
.w-5 {
  width: 5rem !important;
}
/*custom classes .c */
.wc-15 {
  width: 15% !important;
}
.hc-15 {
  height: 15% !important;
}
.font-0_7 {
  font-size: 0.7rem;
}
.font-1_4 {
  font-size: 1.4rem;
}
.bg-primary {
  background-color: var(--primary-color-dark) !important;
}
.font-white {
  color: var(--color-white);
}
.font-black {
  color: var(--color-black);
}
.font-grey {
  color: #cccccc;
}

.font-secondarycolor {
  color: var(--secondary-color-light);
}
.bg-grey {
  background-color: var(--background-color-grey);
}
.error {
  font-size: 0.8rem;
  color: red;
}
.hide {
  display: none;
  @include media-breakpoint-max(md) {
    display: none;
  }
}
.show {
  // display: inherit;
}
.title {
  font-size: var(--text-size-xl);
  color: var(--primary-color-dark);
  font-family: var(--font-family-headers) !important;
}
.img-aspect-16over9 {
  aspect-ratio: 16/9;
}
.w-fillcontainer {
  width: -webkit-fill-available;
}
.wrapper.switch {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
/**Switch Component*/
.demo {
  button {
    margin-bottom: 0.2rem;
    width: 100%;
  }
}

button.switch {
  //height: 150px;
  margin-bottom: 0.5rem;
  width: calc(33.3% - 0.5rem) !important;

  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bg-light {
    background-color: rgba(255, 255, 255, 0.95) !important;
  }
  i {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  u {
    font-size: 0.7rem;
    text-transform: uppercase;
    text-decoration: none;
  }
  span {
    text-transform: uppercase;
  }
}
button.switch.active {
  background: var(--primary-color-dark) !important;
  color: var(--primary-color-light) !important;
}
.react-responsive-modal-modal {
  margin: 25% auto !important;
}
.uppercase {
  text-transform: uppercase;
}
.ReactModal__Content {
  width: 70%;
  margin: auto;
  @include media-breakpoint-max(md) {
    width: 100%;
  }
}
.ReactModal__Overlay {
  z-index: 99999;
  background: rgba(255, 255, 255, 0.95) !important;
}

/**Login Component*/
.login-form {
  background-image: url("/assets/login.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.login-overlay {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.form-check {
  width: 155px;
  text-align: center;
  margin: auto;
}
.login {
  z-index: 50;
  padding: var(--section-padding);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 400px;
  margin: auto;

  img {
    height: 15px;
    &.logo {
      height: auto;
      width: 50%;
      justify-content: center;
    }
  }
  .social {
    background: white;
    color: grey;
    border-radius: 6px;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-size: 10px;
    font-weight: 400;
  }
  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0;
    text-align: center;
    height: 0;
    margin: 3rem 0;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 400;
    &:before,
    &:after {
      content: " ";
      width: 40%;
      border-bottom: 1px solid #cccccc;
    }
  }
  .login-links {
    font-size: var(--text-size-small);
    a {
      color: var(--primary-color-dark);
    }
  }
}
.login-page-form {
  .title {
    padding: 1rem 0;
    font-size: var(--text-size-xl);
  }
  .login-links a {
    padding: 1rem 0;
  }
  .login-button {
    margin: var(--button-margin);
    width: 10rem;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    @include media-breakpoint-min(sm) {
      // width: 40%;
    }
    @include media-breakpoint-between(md, sm) {
      //width: 70%;
    }
  }
}

.display-form {
  position: absolute;
  height: 19rem;
  right: 1rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    background-color: var(--primary-color-dark) !important;
    color: var(--primary-color-light);
    &:hover {
      background-color: var(--primary-color-dark) !important;
    }
  }
  @include media-breakpoint-max(xs) {
    display: none;
  }
}

/**ImagePromo Component*/
.img-promo-text {
  position: relative;
  margin: auto;
}

/**Header and Navigation*/
.header {
  background-color: var(--header-background-color);
}
@include media-breakpoint-min(xs) {
  .login-header {
    display: none;
  }
}
.logo {
  height: 5rem;
  // margin: 0.5rem 0;
}
.logo-retail {
  height: 3rem;
  margin: 0.5rem 0;
}
.navigation-col {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.navigation-row {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.navigation-header {
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    @include media-breakpoint-min(md) {
      flex-direction: row;
    }
  }
  a {
    color: var(--headerNavigationColor);
    font-family: var(--font-family-headers) !important;
    font-weight: 700;
    &:hover {
      text-decoration: none;
      opacity: 0.85;
    }
  }
  .active {
    color: var(--primary-active-link-color);
  }
  .dropdown-menu {
    background-color: var(--header-background-color);
    --bs-dropdown-zindex: 75;
    .dropdown-item {
      color: var(--headerNavigationColor);
      font-weight: 700;
      &:hover {
        color: var(--primary-active-link-color);
      }
    }
    .dropdown-item:active {
      background-color: var(--bs-dropdown-link-hover-bg);
    }
  }
  .dropdown-toggle {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.cart-badge-header {
  position: absolute;
  top: -0.75rem;
  left: 0.75rem;
}
.cart-item-badge {
  position: relative;
  bottom: 1rem;
  left: 4rem;
}
.welcome_user {
  display: flex;
  flex-direction: row;
}
.user_icon {
  height: 0.8rem;
}

/**Carts Component*/
.cart-products-page {
  @include media-breakpoint-max(xs) {
    flex-direction: column !important;
  }
  display: flex;
  // flex-direction: column;
  align-items: center;
  border: 1px solid #dee2e6;
  margin: 3rem 0;
  padding: var(--section-padding);
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  .cart-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    @include media-breakpoint-max(xs) {
      flex-direction: column !important;
    }
  }
}
.cart-table {
  .cart-row {
    td:first-child img {
      height: 3rem;
      @include media-breakpoint-max(md) {
        height: 3rem;
      }
    }
    .cart-item-quantity {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      .input-group.quantity-input {
        clear: both;
        position: relative;
        @include media-breakpoint-max(sm) {
          width: auto;
          margin-top: -1.5rem;
          float: right;
        }
        input {
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          margin: 0;
          outline: none;
          padding: 0.5rem;
        }
        input[type="button"] {
          background-color: #eeeeee;
          min-width: 1.5rem;
          width: auto;
          transition: all 300ms ease;
        }
        .button-minus,
        .button-plus {
          font-weight: bold;
          height: 1.5rem;
          padding: 0;
          width: 1.5rem;
          position: relative;
        }
        .quantity-field {
          position: relative;
          height: 1.5rem;
          text-align: center;
          width: 2.5rem;
          display: inline-block;
          font-size: 13px;
          margin: 0 0 5px;
          resize: vertical;
        }
        .button-plus {
          left: -3px;
        }
      }
    }
  }
}
.cart-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  .total {
    display: flex;
    .subtotal-text {
      font-weight: 700;
      padding-right: 0.5rem;
    }
  }
}

@include media-breakpoint-max(sm) {
  .cart-table {
    thead {
      display: none;
    }

    tr,
    td {
      border: 0;
    }
    .cart-row {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--color-grey-medium);
      td:first-child img {
        width: 15rem;
      }
      .cart-item-quantity {
        display: inline;
        float: inline-end;
      }
      .cart-item-price,
      .cart-item-amount {
        text-align: right !important;
      }
      td {
        width: 100%;
        padding: 0.5rem 0;
        &:last-child {
          padding-bottom: 1.5rem;
        }
      }
      td::before {
        content: attr(data-label);
        float: left;
      }
    }
  }
}

/**Banner Component*/
.transparency-1 {
  background: rgba(0, 0, 0, 0.3);
  color: var(--primary-color-light);
}
.banner {
  position: relative;
  display: block;
  .banner-content {
    padding: 2rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 4rem;
    padding: 1rem 3rem;
    width: 100%;
  }

  .banner-blurb {
    position: relative;
    max-width: 50%;
  }
  .banner-button {
    position: relative;
    width: fit-content;
    top: 0.5rem;
    padding: var(--button-padding);
  }
}

/**FourColCards Component*/
.cards-4 {
  .card {
    border: 0;
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
/**component overlap **/
.semi-transparent {
  background: rgba(255, 255, 255, 0.6) !important;
  //color:#bba8a8;
}
#publicsector {
  footer {
    border-top: 1px solid #b1b4b6;
  }
  .app-wrapper {
    margin-top: 0rem;
  }
  .header {
    padding: 0 !important;
    background: #0b0c0c;
  }
  .uk-header.uk-black {
    background-color: rgb(0, 0, 0);
    min-height: 1rem;
  }
  .uk-blue {
    background-color: #1d70b8;
    width: 100%;
    color: white;
  }
  .uk-black {
    background-color: rgb(38, 49, 53);
    margin-top: -1px;
    border-top: 1px solid rgb(38, 49, 53);
    min-height: 15rem;

    padding-top: 5rem;
  }
  .uk-gray {
    background: #f3f2f1 !important;
    border-bottom: 1px #b1b4b6 solid;
    color: black;
  }
  .uk-banner {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: start;
  }
  .uk-nav {
    padding-bottom: 17px;
    padding-top: 17px;
  }
  .uk-logo {
    padding: 5px 0 15px 0;
    svg {
      color: white;
    }
  }
  .btn-primary-uk {
    color: #f3f2f1;
    background: #1d70b8;
    border-radius: 0 !important;
    padding: 1rem;
    font-weight: 700;
  }
  .btn-secondary-uk {
    background: #f3f2f1;
    color: #1d70b8;
    border-radius: 0 !important;
    padding: 1rem;
    font-weight: 700;
  }
  .uk-menu {
    .link {
      padding: 0 1rem;
    }

    a {
      padding: 1rem;
      border-bottom: 3px solid #f3f2f1;
      color: #1d70b8;
      i {
        color: #f3f2f1;
      }
      &.active {
        background: #f3f2f1;
        color: #1d70b8;
        opacity: 1;
        span {
          color: #1d70b8 !important;
        }
        i {
          color: #1d70b8;
        }
      }
    }
  }
  .uk-block {
    width: 70%;
  }
  .uk-drop-menu a {
    color: #1d70b8;
    text-decoration: underline;
    font-weight: 700;
  }
  .uk-blue {
    h1 {
      .fs-1 {
        font-size: 4rem !important;
      }
    }
  }
  .uk-black {
    h1 {
      margin-bottom: 2rem !important;
      .fs-1 {
        font-size: 3rem !important;
      }
    }
  }
  .uk-border-top {
    border-top: 5px solid #1d70b8;
    padding-top: 4rem;
  }

  .breadcrumbs {
    padding: 0;
    margin: 0.5rem 0;
    li {
      list-style: none;
      padding: 0;
    }
  }
  .links-component h2 {
    text-align: unset;
    margin-bottom: 2rem;
  }
  .links-component a {
    margin-bottom: 3rem;
  }
  .banner {
    h2 {
      font-size: 4rem !important;
      opacity: 0.8;
    }
  }
  .banner .banner-content {
    padding: 2rem 0 4rem 0;
    bottom: unset;
    position: relative;
  }
  .card-title {
    font-size: 2.25rem !important;
  }
  .navigation-header .dropdown-toggle {
    padding-top: 1px;
  }
  .login-button {
    a {
      span {
        font-family: Arial, Helvetica, sans-serif !important;
        text-transform: capitalize !important;
        font-size: 1.1875rem !important;
      }
    }
  }
  .cart-icon {
    display: none;
  }
  .links-component {
    &.promos {
      a.uk-link-icon {
        i.fa-solid {
          font-size: 2rem !important;
          &:before {
            width: 3rem !important;
            height: 3rem;
            background-position: 0 100%;
            background-color: var(--chart-green-light);
            padding: 1.75rem;
            border-radius: 0 !important;
            color: #414141;
          }
        }
      }
      &.purple {
        a.uk-link-icon {
          i.fa-solid {
            font-size: 2rem !important;
            &:before {
              width: 3rem !important;
              height: 3rem;
              background-position: 0 100%;
              background-color: var(--bedges-blue);
              padding: 1.75rem;
              border-radius: 0 !important;
              color: #414141;
            }
          }
        }
      }
    }
    &.uk-no-icon {
      a {
        border-bottom: 1px solid #b1b4b6;
        padding-bottom: 1.5rem;
        justify-content: space-between;
        width: calc(100% - 2rem);

        i {
          margin-right: 0.5rem;
          font-size: 1.2rem;
          &:before {
            width: 1.5rem;
            height: 1.5rem;
            background-position: 0 50%;
            background-color: transparent;
            padding: 0.75rem;
            border-radius: 50%;
            color: #1d70b8;
          }
        }
      }
    }

    a {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 3rem !important;
      span {
        span {
          color: #1d70b8 !important;
          text-decoration: underline !important;
        }
        i {
          font-weight: 500;
          color: black !important;
          font-style: normal;
        }
      }
    }
    a:hover {
      background: none;
      i:hover {
        background: none;
      }
    }
  }
  .links-component a:hover,
  .links-component a:hover i {
    background: none;
    opacity: 1;
  }
  .links-component a:hover {
    i:before {
      background-color: #1d70b8;
      color: #f4f4f4;
    }
  }
  .dark-icons {
    .uk-link-icon {
      i {
        margin-right: 0.5rem;
        font-size: 1.2rem;
        &:before {
          width: 1.5rem;
          height: 1.5rem;
          background-position: 0 50%;
          background-color: rgb(38, 49, 53);
          padding: 0.75rem;
          border-radius: 50%;
          color: #f4f4f4;
        }
      }
    }
  }
  .uk-link-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3rem;
    a {
      width: 100%;

      &:hover {
        background-color: white !important;
      }
    }
    i {
      margin-right: 0.5rem;
      font-size: 1.2rem;
      &:before {
        width: 2rem;
        height: 2rem;
        background-size: 40px auto;
        background-position: 0 50%;
        background-color: #f4f4f4;
        padding: 1rem;
        border-radius: 50%;
        color: black;
      }
    }
    span {
      display: flex;
      flex-direction: column;
    }
  }
  .navbar {
    .row {
      @include media-breakpoint-max(md) {
        margin: 0 !important;
      }
    }
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%283255, 255, 255, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  }
  .navigation-header .dropdown-menu .dropdown-item span:hover {
    color: #003078;
  }
  .navigation-header .dropdown-menu .dropdown-item span,
  .navigation-header a {
    font-family: Arial, Helvetica, sans-serif !important;
    text-transform: capitalize;
    font-size: 1.1875rem !important;
    padding: 10px 10px 9px 10px !important;
    white-space: nowrap;
  }
  .navigation-header a.col-2.px-2.font-dark {
    padding: 4px 0 15px 0;
  }

  .navigation-header .active {
    color: #003078;
    border-bottom: 2px solid #003078;
    padding-bottom: 1rem;
    font-weight: bold;
  }

  .navigation-header a:hover {
    color: #003078;
    text-decoration: underline;
  }
  .navigation-header {
    background-color: #f8f8f8;
    border-top: 5px solid #1d70b8;
  }
  .navigation-header.py-2 {
    padding: 0rem !important;
    border-bottom: 1px solid #b1b4b6;
  }
  .navbar-expand-lg .navbar-collapse {
    background-color: #f8f8f8;
    border-top: 5px solid #1d70b8;
    border-bottom: 1px solid #b1b4b6;
    @media only screen and (min-width: 991px) {
      height: 54px;
    }
  }
  .navbar-expand-lg .navbar-collapse {
    .navigation-header {
      border-top: none;
      @include media-breakpoint-min(lg) {
        height: 3rem;
      }
    }
  }
  .navbar-expand-lg {
    padding-bottom: 0px !important;
  }
  .navbar-brand {
    display: block;
    @media only screen and (min-width: 991px) {
      display: block;
      width: 100%;
      padding: 1rem !important;
    }

    a {
      @include media-breakpoint-min(lg) {
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
      }
      img {
        max-width: 230px;
        width: 230px;
        height: auto;
      }
    }
  }

  .single-card a:hover {
    background: #f4f4f4;
  }
  .container-component {
    margin: 4rem 0;
  }
  .pulledUp {
    padding: 1rem 2rem;
    margin-top: -14rem;
    box-shadow: unset;
  }
}

#environmental,
#finance {
  .container-component {
    margin: 3rem 0;
  }
  .pulledUp {
    padding: 1rem 2rem;
  }
}
#healthcare {
  .container-component {
    margin: 3rem 0;
  }
  .pulledUp {
    position: relative;
    margin-top: -8rem;
    background: white;
    padding: 1rem;
    border-radius: 0.375rem;
    box-shadow: 0 -10px 12px -5px rgba(115, 115, 115, 0.75);
  }
  h1,
  h2,
  h3,
  h4,
  .card-title,
  .h5,
  .title {
    font-family: var(--font-family-headers) !important;
    color: var(--headerNavigationColor) !important;
  }
  .banner-content h3 {
    width: 100%;
    @include media-breakpoint-min(lg) {
      width: 36%;
    }

    float: right;
    background: rgba(255, 255, 255, 0.4);
    padding: 1rem;
    border-radius: 0.5rem;
  }
}
.pulledUp {
  position: relative;
  margin-top: -8rem;
  background: white;
  padding: 4rem;
  border-radius: 0.375rem;
  box-shadow: 0 -10px 12px -5px rgba(115, 115, 115, 0.75);
}
.pulledDown {
  position: relative;
  //margin-bottom: -7rem;
  background: white;
  padding: 4rem;
  border-radius: 0.375rem;
  box-shadow: 0px 10px 12px -5px rgba(115, 115, 115, 0.75);
  //z-index: 9999;
}
.downsized {
  width: 80%;
  margin: -8rem auto 0 auto;
  background: white !important;
  border-radius: 0.375rem !important;
}
.radiusLeft {
  border-radius: 0.375rem 0 0 0.375rem;
}
.radiusRight {
  border-radius: 0 0.375rem 0.375rem 0;
}
/**PromotionList Component*/
.promotions {
  height: 19rem;
  overflow: overlay;
}

/**SingleCard Component and CardContainer*/
.single-card {
  &.card {
    border: 0;
    border-radius: 0;
    height: 100%;
    padding: 0.5rem 0;
    .wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .card-buttons {
        // position: absolute;
        // bottom: 0;
        margin: auto;
      }
    }
  }
}

/**Footer Component*/
.Twilio {
  .Twilio-MainHeader {
    background-color: var(--primary-color-dark);
    div {
      color: var(--primary-color-light) !important;
    }
  }
  button {
    background-image: linear-gradient(
      to top,
      var(--primary-color-dark),
      var(--primary-color-dark)
    );
  }
}

.footer {
  position: relative;
  background-color: var(--footer-background-color);
  color: var(--color-footer);
  --bs-gutter-x: 0;

  .footer-links {
    list-style: none;
    display: flex;
    li {
      padding: 0 5px;
      border-right: 1px solid var(--footer-link-color);
      display: inline-flex;
      color: var(--color-footer);
      &:last-child {
        padding: 0 10px;
        border-right: 0;
        display: inline-flex;
      }
    }
    a {
      color: var(--color-footer);
    }
  }
}
.hgs-logo {
  height: 1.5rem;
}

/**ContactDetails Component*/
.contact-title {
  display: block;
  font-size: var(--text-size-xl);
  font-weight: 500;
  color: var(--primary-color-dark);
  a {
    color: var(--primary-color-dark);
  }
}

/**AccountCard Component*/
.account-details {
  font-size: var(--text-size-medium);
  text-align: center;
}

/**FormContainer Component*/
.form_label {
  font-size: var(--text-size-large);
  font-weight: 700;
  color: var(--primary-color-dark);
}

/**ActivityCard Component*/
.activity-card a {
  color: black;
}

/**ContentComponent & CardContainer*/
.card-container {
  h2 {
    color: var(--primary-color-dark);
    text-align: center;
  }
  a.button {
    border: 1px solid var(--border-color);
    padding: 0.5rem;
    margin: 0.3rem;
    border-radius: 5px;
    text-align: center;
  }
}

/**LinkContainer Component*/
.links-component {
  h2 {
    color: var(--primary-color-dark);
    text-align: center;
  }
  a.button {
    border: 1px solid var(--border-color);
    padding: 0.5rem;
    margin: 0.3rem;
    border-radius: 5px;
    text-align: center;
  }
  a {
    display: flex;
    flex-direction: column;
    i {
      color: var(--text-color-pale);
      font-size: var(--text-size-small);
      display: block;
    }
    &:hover,
    &:hover i {
      color: var(--primary-color-light);
      background: var(--primary-color-dark);
    }
  }
}

/**CatalogItemCard Component*/
.img__wrap {
  position: relative;
  .img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--retail-catalog-desc-bg-color);
    color: var(--retail-catalog-desc-text-color);
    visibility: hidden;
    opacity: 0;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    /* transition effect. not necessary */
    transition: opacity 0.2s, visibility 0.2s;
  }
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent var(--secondary-color-light) transparent
      transparent;
    right: 0;
    top: 0;
    position: absolute;
  }
  &:hover {
    .img__img {
      opacity: 0.3;
    }
    .img__description_layer {
      visibility: visible;
      opacity: 1;
    }
    .img__description {
      transform: translateY(0);
    }
  }
  .img__description {
    transition: 0.2s;
    transform: translateY(1em);
  }
}

/**Checkout Component*/
.checkout {
  .express_buttons {
    hr {
      display: inline-block;
      width: 30%;
      margin: 0 !important;
      padding: 0 !important;
    }

    span {
      width: 40% !important;
      display: inline-block;
      text-align: center;
    }
  }
  .Or_checkout {
    hr {
      display: inline-block;
      width: 40%;
      margin: 0 !important;
      padding: 0 !important;
    }

    span {
      width: 20% !important;
      display: inline-block;
      text-align: center;
    }
  }
  .total {
    font-size: 1.25rem;
    padding-left: 0.5rem;
  }
  .shipping {
    font-size: var(--text-size-small);
    color: var(--color-grey-medium);
  }
  .cart-details {
    padding-top: 1rem;
    border-left: 1px;
    font-size: var(--text-size-medium);
    background-color: var(--background-color-grey);
  }
  .checkout-item-badge {
    --bs-badge-font-size: 0.5em;
    position: relative;
    top: -1.5rem;
    right: 0.5rem;
  }
}

/**ProductDetail Component*/
.prodimg {
  max-width: inherit;
}

/**BlogArticle Component*/
.blog-accordion {
  display: none;
  @include media-breakpoint-max(sm) {
    display: block;
  }
}
.blog-list {
  display: block;
  @include media-breakpoint-max(sm) {
    display: none;
  }

  .navigation-header {
    .nav-item {
      a {
        color: var(--primary-color-dark) !important;
      }
    }
  }
}
.blog-article {
  .blog-card-img {
    width: 50%;
    margin: auto;
  }
}
.card-title {
  font-family: var(--font-family-headers) !important;
}

/**BlogListCard Component*/
.blog-list-card {
  flex-direction: row;
  align-items: center;
  border: 0;
  border-radius: 0;
  padding: 1rem 0;
  color: var(--text-color);
  .card-title {
    font-weight: bold;
    font-family: var(--font-family-headers);
  }
  .card-category {
    color: var(--color-grey-dark);
  }
  .card-img {
    width: 15%;
    border-top-right-radius: 0;
    border-bottom-left-radius: calc(0.25rem - 1px);
    img {
      width: 100%;
    }
  }
  .card-body {
    width: 85%;
  }
  @include media-breakpoint-max(md) {
    .card-body {
      width: 70%;
      padding: 0.5em 1.2em;
      .card-text {
        margin: 0;
      }
    }
    .card-img {
      width: 30%;
    }
  }
}

/**
Finance
*/
.home-lending-guide-img {
  padding: 0.25rem;
  border: 1px solid #dee2e6;
}

/**
Auto
*/
//css to wrap blog card img
.img-wrap {
  width: 10rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  img {
    width: 200%;
    height: 100%;
    max-width: none;
    float: right;
    margin-right: -45%;
  }
}

//Unknown
.story-module {
  position: relative;
  margin-bottom: 1rem;
}
.App {
  text-align: center;
}
.single-card-style {
  border: 0;
  padding-top: 0.5rem;
}
.dropdown-toggle {
  &:after {
    // color: #ccc;
  }
}

//Retired Components
.plan-card-buttons {
  display: flex;
  flex-direction: column;
}
.cards_style {
  width: -webkit-fill-available;
}

@media (min-width: 576px) {
  .cards_style {
    flex: 1 0 0%;
  }
}

/**Loading Spinner*/
.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}
.spinner-border {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);
  z-index: 100;
}

/**UserDetail*/
.user-detail {
  border: 0;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  padding: 1rem;
  margin-bottom: 1rem;
  .user-title {
    padding: 0 0.5rem 1rem 0.5rem;
    display: flex;
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

.password-input {
  position: relative;
  i {
    position: absolute;
    top: 0.7rem;
    right: 1rem;
  }
}

/**StatementCard*/
.statement-card {
  border: 0;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  padding: 1rem;
  margin-bottom: 1rem;
  .statement-title {
    display: flex;
    padding: 0 0.5rem 1rem 0.5rem;
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .send-email-button {
    display: flex;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
}

/*travel*/
#travel {
  main {
    .container {
      .container-component {
        img {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    .banner .banner-blurb {
      width: 50%;
      display: block;
      background: rgba(255, 255, 255, 0.8);
      color: var(--primary-color-dark);
      padding: 1rem;
    }
  }
  .header {
    background-image: linear-gradient(
      to right,
      var(--header-background-color),
      var(--footer-background-color)
    );
  }
  .footer {
    background-image: linear-gradient(
      to left,
      var(--header-background-color),
      var(--footer-background-color)
    );
  }
  .login-form {
    background-image: url("/assets/login-travel.jpg");
  }
}
/*telcom*/
#telecom {
  background-image: linear-gradient(to right, #f4f4f4 0%, #f7f7f7 100%);
  main {
    min-height: 68vh;
  }
  .bg-grey {
    padding: 0.25rem;
  }
  .header {
    background-image: linear-gradient(
      to left,
      var(--header-background-color),
      var(--footer-background-color)
    );
  }
  .footer {
    background-image: linear-gradient(
      to right,
      var(--header-background-color),
      var(--footer-background-color)
    );
  }
  .login-form {
    background-image: url("/assets/login-telecom.jpg");
  }
}
/*retail*/
#retail {
  main {
    background-image: none !important;
    background-color: white;
  }
  .product-detail {
    .price.badge {
      font-size: 2rem;
      top: -100px;
      box-shadow: 0 10px 12px -5px rgba(115, 115, 115, 0.75);
    }
  }
}
/*finance*/
#finance {
  .login {
    background-image: none !important;
    &.display-form {
      margin-top: -4rem;
      z-index: 9999;
    }
    .separator,
    .social {
      display: none;
    }
  }
}

#twilio-webchat-widget-root {
  z-index: 10;
  position: relative;
}

#glance_ssnkey_box.starting + #glance_sessionwaiting_scrim,
#glance_ssnkey_box.keyedwaitcamera + #glance_sessionwaiting_scrim,
#glance_ssnkey_box.keyed + #glance_sessionwaiting_scrim,
#glance_ssnkey_box.integrated + #glance_sessionwaiting_scrim {
  z-index: 99 !important;
}

.fs-9 {
  font-size: 0.8rem !important;
}
.review-date {
  font-weight: 700;
}
.price {
  margin: 50px;
  transform: rotate(-5deg);
  position: absolute;
  right: 0-50px;
  top: -50px;
}

.loaderSpin {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--primary-color-dark);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**SLIDER SLICK **/
.slick-slide {
  //  transform: translateY(100px);
  .slider-item {
    position: relative;
    h2 {
      // top: 2rem;
      // left: 2rem;
      // position: absolute;
      background: rgba(196, 49, 49, 0.6);
      color: var(--primary-color);
      height: 4rem;
      padding: 1rem;
      border-radius: 1rem;
    }
    .desc {
      // position: absolute;
      // bottom: 2rem;
      // right: 5rem;
      // background: rgba(222, 222, 222, 0.6);
      color: var(--primary-color);
    }
  }
}
.slick-prev:before,
.slick-next:before {
  color: var(--primary-active-link-color) !important;
}

@media (min-width: 992px) {
  .angle__overlay:after {
    -webkit-transform: skewX(-11deg);
    transform: skewX(-11deg);
  }
}
.angle {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.angle__overlay {
  height: 100%;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
  opacity: 1 !important;
}

.angle__overlay:after {
  background-color: var(--primary-color-dark);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
/*date picker */
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  box-shadow: 5px 5px 5px #f4f4f4;
  font-size: 1.3rem !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  // width: 13% !important;
}

/*MEDIA VERTICAL*/
#media {
  .text-center {
    text-align: left !important;
  }
  .slider-container {
    border-top: "1px solid #cccccc";
  }
  .product-detail {
    button {
      display: none;
    }
  }
}

.text-shadow {
  text-shadow: 1px 1px #ccc;
}
